import { omitBy, isUndefined } from 'lodash'

import clientSideMetrics from 'libs/common/client-side-metrics'
import { logError, logMessage } from 'libs/utils/window'

const HOME_PREFIX = 'Home'

export const logHomeError = (error: Error | null, feature?: string) => {
  if (!(error instanceof Error)) return

  const loggedFeature = feature ? `${HOME_PREFIX}_${feature}` : HOME_PREFIX

  logError(error, { feature: loggedFeature })
}

export const logHomeMessage = (message: string, extra?: string) => {
  logMessage(message, { feature: HOME_PREFIX, extra })
}

export const incrementPageLoadInitiatedCounter = (tab: string) => {
  clientSideMetrics.counter('home_page_load_initiated', { tab }).increment()
}

export const incrementPageLoadFailureCounter = (labels: {
  tab: string
  feed_reason?: string
  homepage_reason: string
}) => {
  clientSideMetrics.counter('home_page_load_failure', omitBy(labels, isUndefined)).increment()
}

export enum ItemBlockCtaType {
  Header = 'header',
  Item = 'item',
}

export enum BlockEntityType {
  ItemBoxBlock = 'item_box_block',
  ThumbnailsBlock = 'thumbnails_block',
  ExposureBlock = 'exposure_block',
  ItemsCardsBlock = 'items_cards_block',
  BannersBlock = 'banners_block',
}

export enum ThumbnailsBlockStyle {
  SmallImage = 'small_image',
  BigImage = 'big_image',
  TwoHorizontalRows = 'two_horizontal_rows',
  SmallRoundImage = 'small_round_image',
}

export const ALLOWED_TEXT_THEMES = ['light', 'dark'] as const
export const ALLOWED_BUTTON_THEMES = ['primary', 'amplified'] as const

export enum PromoBoxBlockName {
  RecentlyFavouritedItems = 'favourites',
  PopularItems = 'popular_items',
  ItemsBasedOnRecentPurchases = 'recent_purchases_by_brand',
  GenericItemsBlock = 'blocks',
}
